import React from 'react'
import './Pre.css'

function Pre(props) {
  return (
    <>
      <div id={props.load ? 'preloader' : 'preloader-none'}></div>
    </>
  )
}

export default Pre
